import React from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";

function Contact() {

    return (
        <>
            <Header />
            <section
                className="promo-sec"
                style={{
                    background: 'url("assets/images/promo-bg.jpg")no-repeat center center / cover'
                }}
            >
                <img src="assets/images/promo-left.png" alt="" className="anim-img" />
                <img src="assets/images/promo-right.png" alt="" className="anim-img anim-right" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h1 className="display-2 text-white">Contact Us</h1>
                            <nav aria-label="breadcrumb mt-0">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Contact us
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-card-sec sec-padding">
                <div className="container">
                    <div className="row gy-3 gy-md-0">
                        <div className="col-md-4">
                            <div className="card bg-shade text-center">
                                <span className="icon-lg mx-auto bg-secondary text-info rounded-circle mb-4">
                                    <i className="feather-icon icon-phone" />
                                </span>
                                <h5>Call Us</h5>
                                <p>
                                    <a className="text-reset" href="tel:9099966379">
                                    +91 9099966379
                                    </a>
                                </p>
                                {/* <p>
                                    <a className="text-reset" href="tel:029-292-5384">
                                        029-292-743-5384
                                    </a>
                                </p> */}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card bg-shade text-center">
                                <span className="icon-lg mx-auto bg-secondary text-info rounded-circle mb-4">
                                    <i className="feather-icon icon-mail" />
                                </span>
                                <h5>Email Us</h5>
                                <ul>
                                    <li>
                                        <a
                                            className="text-reset"
                                            href="#"
                                        >
                                            <span
                                                className="__cf_email__"
                                                data-cfemail="1960766c6b74787075597e74787075377a7674"
                                            >
                                                amosay08@hotmail.com
                                            </span>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a
                                            className="text-reset"
                                            href="https://html.theme-village.com/cdn-cgi/l/email-protection#522b3d27203f333b3e3336362037212112353f333b3e7c313d3f"
                                        >
                                            <span
                                                className="__cf_email__"
                                                data-cfemail="2b4e4f5e53446b4c464a424705484446"
                                            >
                                                [email&nbsp;protected]
                                            </span>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card bg-shade text-center">
                                <span className="icon-lg mx-auto bg-secondary text-info rounded-circle mb-4">
                                    <i className="feather-icon icon-map-pin" />
                                </span>
                                <h5>Locations</h5>
                                <p>A306, park rest society, behind l&t knowledge city wagoriya, Vadodara(390019).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact2-sec sec-padding pt-0">
                <div className="container">
                    <div className="text-center mb-5 pb-4">
                        <h2 className="sec-title mb-1">Contact with Us</h2>
                        <p>We are here to answer any question you may have any time.</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="contact-form">
                                <form action="#" className="row">
                                    <div className="col-lg-6 form-group">
                                        <i className="feather-icon icon-user" />
                                        <input type="text" placeholder="Name" />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <i className="feather-icon icon-mail" />
                                        <input type="text" placeholder="Email Address" />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <i className="feather-icon icon-pocket" />
                                        <input type="text" placeholder="Your Subject" />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <i className="feather-icon icon-phone-call" />
                                        <input type="text" placeholder="Phone Number" />
                                    </div>
                                    <div className="col-lg-12 form-group">
                                        <textarea
                                            name="message"
                                            id="message"
                                            rows={6}
                                            placeholder="Enter your message"
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className="col-lg-12 text-center">
                                        <button className="btn btn-primary rounded-5 mt-4">
                                            Submit Now
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <iframe
                className="google-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14708.463353596799!2d73.15993789999999!3d22.32464515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc4bf25b1361f%3A0x3025bfcde1a62cb1!2sPark%20Rest%20Society%2C%20Behind%20L%26T%20Knowledge%20City%2C%20Wagoriya%2C%20Vadodara%2C%20Gujarat%20390019!5e0!3m2!1sen!2sin!4v1699804968159!5m2!1sen!2sin"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            />
            <Footer />
        </>

    );
}

export default Contact;
