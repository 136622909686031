import React from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import { useEffect } from 'react';

function Home() {
  useEffect(() => {
    // Initialize PureCounter after the component is mounted
    if (window.PureCounter) {
      new window.PureCounter(); // Initialize the pure counter
    }

  }, []); // This runs only after the component is mounted

  return (
    <>
      <Header />
      <section className="banner-sec2 position-relative overflow-hidden">
        <div
          className="banner2-bg"
          style={{ backgroundImage: 'url("assets/images/banner-2.png")' }}
        />
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-7">
              <div className="banner-content">
                <div className="img-meta position-absolute">
                  <img src="assets/images/icons/shape1.png" alt="" />
                </div>
                <h1>
                  Building <span className="color">Success </span> through People, One Hire at a Time

                </h1>
                <p className="lead-sm">
                  We take pride in building strong, capable teams for businesses across industries. Our carefully selected workforce empowers you to focus on what matters—growing your business.
                </p>
                <form action="#" className="subscribe-form">
                  <input type="email" placeholder="Enter your email address" />
                  <button className="btn btn-primary rounded-5">
                    Subscribe Now
                  </button>
                </form>
                <div className="profile-ratings d-lg-flex align-items-center">
                  <div className="ratings-avatar d-flex me-3 ">
                    <a
                      className="icon"
                      href="#"
                      data-bs-toggle="tooltip"
                      title="Adams"
                    >
                      <img src="assets/images/avatar4.png" alt="avatar" />
                    </a>
                    <a
                      className="icon"
                      href="#"
                      data-bs-toggle="tooltip"
                      title="Louise"
                    >
                      <img src="assets/images/avatar5.png" alt="avatar" />
                    </a>
                    <a
                      href="#"
                      className="bg-secondary rounded-circle text-info fw-bold s-rating"
                      data-bs-toggle="tooltip"
                      title="Overall Ratings"
                    >
                      4.9
                      <i className="feather-icon icon-star" />
                    </a>
                  </div>
                  <div className="ms-lg-5">
                    <h5 className="m-0">Candidate Reviews</h5>
                    <small>2000+ Fully Satisfied Candidate</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="brand-sec bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="brands-wrap swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide brand-item text-center">
                    <a href="#">
                      <img src="assets/images/br1.png" alt="Brand" />
                    </a>
                  </div>
                  <div className="swiper-slide brand-item text-center">
                    <a href="#">
                      <img src="assets/images/br2.png" alt="Brand" />
                    </a>
                  </div>
                  <div className="swiper-slide brand-item text-center">
                    <a href="#">
                      <img src="assets/images/br3.png" alt="Brand" />
                    </a>
                  </div>
                  <div className="swiper-slide brand-item text-center">
                    <a href="#">
                      <img src="assets/images/br4.png" alt="Brand" />
                    </a>
                  </div>
                  <div className="swiper-slide brand-item text-center">
                    <a href="#">
                      <img src="assets/images/br5.png" alt="Brand" />
                    </a>
                  </div>
                  <div className="swiper-slide brand-item text-center">
                    <a href="#">
                      <img src="assets/images/br1.png" alt="Brand" />
                    </a>
                  </div>
                  <div className="swiper-slide brand-item text-center">
                    <a href="#">
                      <img src="assets/images/br2.png" alt="Brand" />
                    </a>
                  </div>
                  <div className="swiper-slide brand-item text-center">
                    <a href="#">
                      <img src="assets/images/br3.png" alt="Brand" />
                    </a>
                  </div>
                  <div className="swiper-slide brand-item text-center">
                    <a href="#">
                      <img src="assets/images/br4.png" alt="Brand" />
                    </a>
                  </div>
                  <div className="swiper-slide brand-item text-center">
                    <a href="#">
                      <img src="assets/images/br5.png" alt="Brand" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="about-sec sec-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-7">
              <div className="about-media-2 position-relative mb-4 mb-xl-0">
                <img style={{ marginLeft: "-285px" }}
                  className="ab-media-1 img-fluid"
                  src="assets/images/about-n1.png"
                  alt="About"
                />
                <img
                  className="ab-media-2 position-absolute img-fluid"
                  src="assets/images/about-n2.png"
                  alt="About"
                />
                <img
                  className="ab-media-3 position-absolute img-fluid"
                  src="assets/images/about-n3.png"
                  alt="About"
                />
              </div>
            </div>
            <div className="col-xl-5 px-lg-0">
              <div className="about-content">
                <span className="badge-lg bg-primary rounded-5">About Us</span>
                <h2 className="sec-title">
                  Empowering Workforce <span className="color">Solutions</span>
                </h2>
                <p className="lead">
                  At Max Education, we believe that people are the backbone of every successful business, and meaningful, sustainable employment has the power to change the world.
                </p>
                <p className="justify">
                  As a leading provider of manpower solutions, we are dedicated to connecting businesses with the skilled and qualified talent they need to thrive in today’s dynamic marketplace. With years of industry experience, we understand the unique challenges that businesses face when it comes to finding the right workforce.
                </p>
                {/* <p className="justify">
                  Whether you need temporary staffing, contractual placements, or specialized professionals, we offer a wide range of workforce solutions designed to meet your requirements. Our commitment to quality, integrity, and customer satisfaction sets us apart as a trusted partner in the manpower industry.
                </p> */}
                <div className="about-cta mt-5 d-flex">
                  <a href="/about" className="btn btn-primary shadow rounded-5 me-5">
                    Learn More
                  </a>
                  {/* <a
                    href="https://www.youtube.com/watch?v=tUP5S4YdEJo"
                    className="d-flex align-items-center text-reset play-btn"
                  >
                    <span className="icon bg-secondary rounded-circle me-4">
                      <img src="assets/images/icons/play-w.png" alt="Play" />
                    </span>
                    How It Works
                  </a> */}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <section className="admission-guide sec-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="admission-media position-relative">
                <img
                  src="assets/images/admission.jpg"
                  alt="About"
                  className="img-fluid rounded-3"
                />
                {/* <div className="video-block">
              <div className="waves wave-1" />
              <div className="waves wave-2" />
              <div className="waves wave-3" />
               <a
                className="cover-video"
                href="https://www.youtube.com/watch?v=tUP5S4YdEJo"
              >
                <img src="assets/images/icons/play.png" alt="" />
              </a>
            </div> */}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="choose-txt ps-5">
                <h3 className="display-3 fw-bold mb-3 left">
                 Our Mission
                </h3>
                <p className="left">
                Our mission is to simplify staffing and workforce management, 
                delivering tailored solutions that help companies grow, innovate,
                 and succeed. And give the opportunity to the Indian professional workforce from Skilled, Technical to Unskilled workers to work with innovative companies.  
                </p>
                <h6 className="left">
                Our Vision
                </h6>
                <p className="left">
                To become the most reliable and preferred partner for businesses seeking manpower solutions,
                 empowering them to achieve sustained success through the power of exceptional talent.
                  Combining talented people with innovative companies, we can build a brighter future for everyone.
                </p>
                <h6 className="left">
                Why Choose Us?
                </h6>
                <p className="left">
                At Max Education, we don’t just provide manpower—we provide the right people to fuel your company’s success. Let us help you build a stronger, more capable workforce.
                </p>
                <h6 className="left">
                Our Services
                </h6>
                <p className="left">
                At Max Education, we offer a broad range of manpower solutions to cater to the unique needs of various industries. Whether you're seeking technical expertise, skilled professionals, or unskilled labor, we have the right talent to support your business objectives. Our services include.
                </p>

                {/* <p clsssName="left">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias
                  mollitia ipsam recusandae maxime eaque magnam!
                </p> */}
                {/* <a href="#" className="btn btn-primary rounded-5 mt-4">
                  Download Brochure/Flyer
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="course-sec-2 sec-padding bg-shade">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="sec-intro">
                <span className="badge-lg bg-primary rounded-5">
                  Trending Services
                </span>
                <h2 className="sec-title">
                  Popular <span className="color"> Services</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-7">
              {/* <div className="d-lg-flex align-items-center justify-content-center mt-lg-5">
                <div className="portfolio-menu" data-target=".filter-container">
                  <ul className="nav gap-2">
                    <li className="nav-item">
                      <a href="#" className="nav-link active" data-filter="*">
                        View All
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link" data-filter=".ui">
                        UI/UX Design <span className="badge">New</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link" data-filter=".development">
                        Development
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link" data-filter=".web">
                        Web Design
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link" data-filter=".marketing">
                        Marketing
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          <div
            className="row g-4 filter-container mt-xl-3"
            data-isotope='{"layoutMode": "masonry"}'
          >
            <div className="col-md-6 col-xl-3 grid-item marketing business ui">
              <div className="course-entry-2 card position-relative">
                <div className="card-media position-relative overflow-hidden">
                  <a href="single-course.html">
                    <img
                      className="card-img-top"
                      src="assets/images/course-v1.jpg"
                      alt="Course"
                    />
                  </a>
                  <a
                    href="#"
                    className="course-cat badge-lg bg-secondary position-absolute"
                  >
                    IT Technical
                  </a>
                </div>
                <div className="card-body position-absolute bg-white rounded-3 border">
                  {/* <div className="course-meta mb-3">
                    <span>
                      <i className="feather-icon icon-user me-1" />
                      17 Students
                    </span>
                    <span>
                      <i className="feather-icon icon-file-text me-1" />
                      11 Lessions
                    </span>
                  </div> */}
                  <h3 className="sub-title">
                    <a href="single-course.html">
                    Top IT Talent for Your Tech Needs
                    </a>
                  </h3>
                  <div className="course-hover">
                    <div className="d-flex align-items-center rating-wrap">
                      <div className="ratings me-2">
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                      </div>
                      <span className="rating-count" style={{fontSize:'11px'}}>(15) Reviews</span>
                    </div>
                    <div className="course-footer d-flex align-items-center justify-content-between pt-3 border-top mt-3">
                      {/* <div className="price">
                        <del>$35.00</del>$20.00
                      </div> */}
                      <a href="#" className="text-primary fw-bold">
                        Join Now <i className="feather-icon icon-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 grid-item  development">
              <div className="course-entry-2 card position-relative">
                <div className="card-media position-relative overflow-hidden">
                  <a href="single-course.html">
                    <img
                      className="card-img-top"
                      src="assets/images/course-v3.jpg"
                      alt="Course"
                    />
                  </a>
                  <a
                    href="#"
                    className="course-cat badge-lg bg-secondary position-absolute"
                  >
                   Non-IT Technical
                  </a>
                </div>
                <div className="card-body position-absolute bg-white rounded-3 border">
                  {/* <div className="course-meta mb-3">
                    <span>
                      <i className="feather-icon icon-user me-1" />
                      17 Students
                    </span>
                    <span>
                      <i className="feather-icon icon-file-text me-1" />
                      11 Lessions
                    </span>
                  </div> */}
                  <h3 className="sub-title">
                    <a href="single-course.html">
                    Specialized Non-IT Professionals for Various Industries
                    </a>
                  </h3>
                  <div className="course-hover">
                    <div className="d-flex align-items-center rating-wrap">
                      <div className="ratings me-2">
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                      </div>
                      <span className="rating-count" style={{fontSize:'11px'}}>(15) Reviews</span>
                    </div>
                    <div className="course-footer d-flex align-items-center justify-content-between pt-3 border-top mt-3">
                      {/* <div className="price">
                        <del>$35.00</del>$20.00
                      </div> */}
                      <a href="#" className="text-primary fw-bold">
                        Enroll Now <i className="feather-icon icon-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 grid-item marketing web">
              <div className="course-entry-2 card position-relative">
                <div className="card-media position-relative overflow-hidden">
                  <a href="single-course.html">
                    <img
                      className="card-img-top"
                      src="assets/images/course-v4.jpg"
                      alt="Course"
                    />
                  </a>
                  <a
                    href="#"
                    className="course-cat badge-lg bg-secondary position-absolute"
                  >
                   Non-Technical Unskilled
                  </a>
                </div>
                <div className="card-body position-absolute bg-white rounded-3 border">
                  {/* <div className="course-meta mb-3">
                    <span>
                      <i className="feather-icon icon-user me-1" />
                      17 Students
                    </span>
                    <span>
                      <i className="feather-icon icon-file-text me-1" />
                      11 Lessions
                    </span>
                  </div> */}
                  <h3 className="sub-title">
                    <a href="single-course.html">
                    Dependable General Labor for Smooth Operations
                    </a>
                  </h3>
                  <div className="course-hover">
                    <div className="d-flex align-items-center rating-wrap">
                      <div className="ratings me-2">
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                      </div>
                      <span className="rating-count"style={{fontSize:'11px'}}>(15) Reviews</span>
                    </div>
                    <div className="course-footer d-flex align-items-center justify-content-between pt-3 border-top mt-3">
                      {/* <div className="price">
                        <del>$35.00</del>$20.00
                      </div> */}
                      <a href="#" className="text-primary fw-bold">
                        Enroll Now <i className="feather-icon icon-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 grid-item web development">
              <div className="course-entry-2 card position-relative">
                <div className="card-media position-relative overflow-hidden">
                  <a href="single-course.html">
                    <img
                      className="card-img-top"
                      src="assets/images/course-v5.jpg"
                      alt="Course"
                    />
                  </a>
                  <a
                    href="#"
                    className="course-cat badge-lg bg-secondary position-absolute"
                  >
                    Skilled Workforce
                  </a>
                </div>
                <div className="card-body position-absolute bg-white rounded-3 border">
                  {/* <div className="course-meta mb-3">
                    <span>
                      <i className="feather-icon icon-user me-1" />
                      17 Students
                    </span>
                    <span>
                      <i className="feather-icon icon-file-text me-1" />
                      11 Lessions
                    </span>
                  </div> */}
                  <h3 className="sub-title">
                    <a href="single-course.html">
                      {/* Health &amp; Wellness Foundation Nurturing a Balanced
                      Lifestyle */}
                      Qualified Skilled Trades for Quality Projects
                    </a>
                  </h3>
                  <div className="course-hover">
                    <div className="d-flex align-items-center rating-wrap">
                      <div className="ratings me-2">
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/images/icons/star.png" alt="" />
                        </a>
                      </div>
                      <span className="rating-count"style={{fontSize:'11px'}}>(15) Reviews</span>
                    </div>
                    <div className="course-footer d-flex align-items-center justify-content-between pt-3 border-top mt-3">
                      {/* <div className="price">
                        <del>$35.00</del>$20.00
                      </div> */}
                      <a href="#" className="text-primary fw-bold">
                        Enroll Now <i className="feather-icon icon-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </section>
      <div className="stat-sec">
        <div className="container">
          <div className="row stat-wrap row-cols-sm-2 row-cols-xl-4 g-2 bg-primary rounded-3">
            <div className="col">
              <div className="d-flex stat-item align-items-center">
                <span className="icon-lg rounded-circle">
                  <img src="assets/images/icons/graduation-cap.png" alt="graduate" />
                </span>
                <div className="stat-info text-info ms-4">
                  <div className="display-3 fw-bold">
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={2548}
                      className="purecounter"
                    >
                      0
                    </span>
                    +
                  </div>
                  <p>Total Candidate </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex stat-item align-items-center">
                <span className="icon-lg rounded-circle">
                  <img src="assets/images/icons/graduate.png" alt="" />
                </span>
                <div className="stat-info text-info ms-4">
                  <div className="display-3 fw-bold">
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={50}
                      className="purecounter"
                    >
                      0
                    </span>
                    +
                  </div>
                  <p>Jobs Location</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex stat-item align-items-center">
                <span className="icon-lg rounded-circle">
                  <img src="assets/images/icons/open-book.png" alt="" />
                </span>
                <div className="stat-info text-info ms-4">
                  <div className="display-3 fw-bold">
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={2000}
                      className="purecounter"
                    >
                      0
                    </span>
                    +
                  </div>
                  <p>Premium Jobs </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex stat-item align-items-center">
                <span className="icon-lg rounded-circle">
                  <img width={38} src="assets/images/icons/partners.png" alt="" />
                </span>
                <div className="stat-info text-info ms-4">
                  <div className="display-3 fw-bold">
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={15}
                      className="purecounter"
                    >
                      0
                    </span>
                    +
                  </div>
                  <p>Affiliated Partners</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="pricing-sec sec-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sec-intro text-center">
                <span className="badge-lg bg-primary rounded-5">Pricing Plan</span>
                <h2 className="sec-title">
                  Choose Your <span className="color">Pricing Plan</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center g-4">
            <div className="col-md-6 col-lg-4">
              <div className="card pricing-card bg-shade border rounded-4">
                <div className="card-head">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h2 className="display-2">
                      <span>$</span>19 <sub>/month</sub>
                    </h2>
                    <span className="badge-lg bg-secondary rounded-5">Basic</span>
                  </div>
                  <p>
                    <img
                      className="me-2"
                      src="assets/images/icons/wallet-2.png"
                      alt="wallet"
                    />
                    Billed annually or yearly
                  </p>
                </div>
                <div className="card-body bg-white border rounded-2 text-left">
                  <ul>
                    <li>Downloadable Resources</li>
                    <li>Course Certification</li>
                    <li>Instructor Interaction</li>
                    <li>Exclusive Content Update</li>
                    <li>Access to Premium Courses</li>
                  </ul>
                </div>
                <a href="#" className="btn btn-outline-primary">
                  Choose your Plan
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card pricing-card bg-shade border rounded-4">
                <div className="card-head">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h2 className="display-2">
                      <span>$</span>29 <sub>/month</sub>
                    </h2>
                    <span className="badge-lg bg-secondary rounded-5">
                      Standard
                    </span>
                  </div>
                  <p>
                    <img
                      className="me-2"
                      src="assets/images/icons/wallet-2.png"
                      alt="wallet"
                    />
                    Billed annually or yearly
                  </p>
                </div>
                <div className="card-body bg-white border rounded-2 tect text-left">
                  <ul>
                    <li>Downloadable Resources</li>
                    <li>Course Certification</li>
                    <li>Instructor Interaction</li>
                    <li>Exclusive Content Update</li>
                    <li>Access to Premium Courses</li>
                  </ul>
                </div>
                <a href="#" className="btn btn-outline-primary">
                  Choose your Plan
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card pricing-card bg-shade border rounded-4">
                <div className="card-head">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h2 className="display-2">
                      <span>$</span>39 <sub>/month</sub>
                    </h2>
                    <span className="badge-lg bg-secondary rounded-5">Premium</span>
                  </div>
                  <p>
                    <img
                      className="me-2"
                      src="assets/images/icons/wallet-2.png"
                      alt="wallet"
                    />
                    Billed annually or yearly
                  </p>
                </div>
                <div className="card-body bg-white border rounded-2 text-left">
                  <ul>
                    <li>Downloadable Resources</li>
                    <li>Course Certification</li>
                    <li>Instructor Interaction</li>
                    <li>Exclusive Content Update</li>
                    <li>Access to Premium Courses</li>
                  </ul>
                </div>
                <a href="#" className="btn btn-outline-primary">
                  Choose your Plan
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
     
    
      <Footer />
    </>

  );
}

export default Home;
