import React from "react";

function Header() {

    return (
        <>
            {/* <div id="preloader">
                <div className="preloader">
                    <span />
                    <span />
                </div>
            </div> */}
            <header className="header header-2">
                <div className="sticky-height" />
                <div className="top-bar bg-secondary py-2">
                    <div className="container-fluid px-lg-5 px-3">
                        <div className="d-flex justify-content-between">
                            <div className="quick-connect d-none d-xl-flex align-items-center">
                                <span className="d-none d-lg-block">
                                    <img className="me-2" src="assets/images/icons/phone.png" alt="" />
                                    Call Us:{" "}
                                    <a className="text-reset" href="tel:1022212384">
                                        {" "}
                                        +91 9099966379
                                    </a>
                                </span>
                                <span>
                                    <img
                                        className="me-2"
                                        src="assets/images/icons/message.png"
                                        alt="Message"
                                    />
                                    <a href="#">
                                        <span
                                            className="__cf_email__"
                                            data-cfemail=""
                                        >
                                            {/* [email&nbsp;protected] */}
                                            amosay08@hotmail.com
                                        </span>
                                    </a>
                                </span>
                            </div>
                            <div className="topbar-text dropdown d-xl-none">
                                <a
                                    className="text-info fw-bold dropdown-toggle"
                                    href="#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Quick Connect
                                </a>
                                <ul className="dropdown-menu bg-secondary p-2 p-sm-3 border-0 shadow-sm">
                                    <li>
                                        <span>
                                            <img
                                                className="me-2"
                                                src="assets/images/icons/message.png"
                                                alt="Message"
                                            />
                                            <a href="#">
                                                <span
                                                    className="__cf_email__"
                                                    data-cfemail="8feaebfaf7e0e6e1e9e0cfe8e2eee6e3a1ece0e2"
                                                >
                                                    {/* [email&nbsp;protected] */}
                                                    amosay08@hotmail.com
                                                </span>
                                            </a>
                                        </span>
                                    </li>
                                    <li>
                                        <a className="text-info" href="tel:1022213384">
                                            <img className="me-2" src="assets/images/icons/phone.png" alt="" />
                                            +91 9099966379
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {/* <span className="announce-offer d-none d-md-block">
                                <img
                                    className="me-2"
                                    width={25}
                                    src="assets/images/icons/motivation.png"
                                    alt="Offer"
                                />
                                Get Eduxo for Big Sale 75 <sup>%</sup> OFF.
                            </span>*/}
                            <div className="d-flex align-items-center info-right justify-content-end">

                                <div className="social-share-alt d-flex align-items-center justify-content-end">
                                    <span className="d-none d-sm-block">Follow Us:</span>
                                    <span>
                                        <a href="#">
                                            <img src="assets/images/icons/fb-w.png" alt="Facebook" />
                                        </a>
                                    </span>
                                    <span>
                                        <a href="#">
                                            <img src="assets/images/icons/tw-w.png" alt="twitter" />
                                        </a>
                                    </span>
                                    <span>
                                        <a href="#">
                                            <img src="assets/images/icons/linkedin-w.png" alt="Linkedin" />
                                        </a>
                                    </span>
                                    <span>
                                        <a href="#">
                                            <img src="assets/images/icons/ins-w.png" alt="Instagram" />
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="search-popup offcanvas offcanvas-top"
                    id="offcanvas-search"
                    data-bs-scroll="true"
                >
                    <div className="container d-flex flex-row py-5 align-items-center position-relative">
                        <button
                            type="button"
                            className="btn-close bg-primary rounded-5"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        />
                        <div className="col-lg-9 mx-auto">
                            <form className="search-form w-100 mb-5">
                                <input
                                    id="search-form"
                                    type="text"
                                    className="form-control shadow-1"
                                    placeholder="Type keyword and hit enter"
                                />
                            </form>
                            <div className="product-wrap d-none d-sm-block">
                                <h6>Our Best Selling Courses</h6>
                                <div className="row mt-3">
                                    <div className="col-sm-4">
                                        <div className="course-entry-3 card rounded-2 border shadow-1">
                                            <div className="card-media position-relative">
                                                <a href="#">
                                                    <img
                                                        className="card-img-top"
                                                        src="assets/images/course1.jpg"
                                                        alt="Course"
                                                    />
                                                </a>
                                            </div>
                                            <div className="card-body p-3">
                                                <div className="d-flex justify-content-between align-items-center small">
                                                    <div className="d-flex align-items-center small">
                                                        <div className="ratings me-2">
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                        </div>
                                                        <span className="rating-count">(15)</span>
                                                    </div>
                                                </div>
                                                <h3 className="display-6 mt-1">
                                                    <a href="single-course.html">Python Bootcamp Canada</a>
                                                </h3>
                                                <span className="fw-bold">$75.00</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="course-entry-3 card rounded-2 border shadow-1">
                                            <div className="card-media position-relative">
                                                <a href="single-course.html">
                                                    <img
                                                        className="card-img-top"
                                                        src="assets/images/course2.jpg"
                                                        alt="Course"
                                                    />
                                                </a>
                                            </div>
                                            <div className="card-body p-3">
                                                <div className="d-flex justify-content-between align-items-center small">
                                                    <div className="d-flex align-items-center small">
                                                        <div className="ratings me-2">
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                        </div>
                                                        <span className="rating-count">(5)</span>
                                                    </div>
                                                </div>
                                                <h3 className="display-6 mt-1">
                                                    <a href="single-course.html">
                                                        Building wealth &amp; security
                                                    </a>
                                                </h3>
                                                <span className="fw-bold">$75.00</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="course-entry-3 card rounded-2 border shadow-1">
                                            <div className="card-media position-relative">
                                                <a href="single-course.html">
                                                    <img
                                                        className="card-img-top"
                                                        src="assets/images/course3.jpg"
                                                        alt="Course"
                                                    />
                                                </a>
                                            </div>
                                            <div className="card-body p-3">
                                                <div className="d-flex justify-content-between align-items-center small">
                                                    <div className="d-flex align-items-center small">
                                                        <div className="ratings me-2">
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="assets/images/icons/star.png" alt="" />
                                                            </a>
                                                        </div>
                                                        <span className="rating-count">(09)</span>
                                                    </div>
                                                </div>
                                                <h3 className="display-6 mt-1">
                                                    <a href="single-course.html">Python Bootcamp Canada</a>
                                                </h3>
                                                <span className="fw-bold">$75.00</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-nav-wrapper header-sticky">
                    <nav className="navbar navbar-expand-xl">
                        <div className="container navbar-line">
                            <a className="navbar-brand" href="/">
                                <img src="assets/images/logo.png" alt="Logo" />
                            </a>
                            <div className="header-actions position-relative order-xl-3  d-flex align-items-center justify-content-between">
                                <a
                                    className="text-reset icon"
                                    href="#"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvas-search"
                                >
                                    <i className="feather-icon icon-search" />
                                </a>
                            </div>
                            <button className="navbar-toggler offcanvas-nav-btn" type="button">
                                <span className="feather-icon icon-menu" />
                            </button>
                            <div className="offcanvas bg-info offcanvas-start offcanvas-nav nav-header-margin">
                                <div className="offcanvas-header">
                                    <a href="/" className="text-inverse">
                                        {/* <img src="assets/images/logo.png" alt="Logo" />Max Education */}
                                         <img src="assets/images/logo.png" alt="Logo" />Max Education
                                    </a>
                                    <button
                                        type="button"
                                        className="btn-close bg-primary"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="offcanvas-body pt-0 align-items-center">
                                    <ul className="navbar-nav mx-auto align-items-lg-center">
                                        <li className="nav-item">
                                            <a href="/" className="nav-link">
                                                Home
                                            </a>
                                        </li>

                                        <li className="nav-item dropdown dropdown-fullwidth">
                                            <a
                                                className="nav-link dropdown-toggle"
                                                href="#"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Services
                                            </a>
                                            <div className="dropdown-menu">
                                                <div className="row row-cols-xl-4 row-cols-1">
                                                    <div className="col">
                                                        <div>
                                                            <div>
                                                                <div className="dropdown-header">Get Started</div>
                                                                <a className="dropdown-item" href="/about">
                                                                    About Us
                                                                </a>
                                                                <a className="dropdown-item" href="#">
                                                                    Event Grid
                                                                </a>
                                                                <a className="dropdown-item" href="#">
                                                                    Event List
                                                                </a>
                                                                <a
                                                                    className="dropdown-item"
                                                                    href="#"
                                                                >
                                                                    Event Sidebar
                                                                </a>
                                                                <a
                                                                    className="dropdown-item"
                                                                    href="#"
                                                                >
                                                                    Event Details
                                                                </a>
                                                                {/* <a className="dropdown-item" href="pricing.html">
                                                                    Pricing Plan
                                                                </a>
                                                                <a
                                                                    className="dropdown-item"
                                                                    href="admission-guide.html"
                                                                >
                                                                    Admision Guide
                                                                </a> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div>
                                                            <div className="dropdown-header">Get Started</div>
                                                            <a className="dropdown-item" href="/contact">
                                                                Contact Us
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Instructors
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Profile
                                                            </a>
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                            >
                                                                Become a instructor
                                                            </a>
                                                            {/* <a className="dropdown-item" href="faq.html">
                                                                FAQ
                                                            </a>
                                                            <a className="dropdown-item" href="404.html">
                                                                404 error
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Maintenance
                                                            </a> */}
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="mt-3 mt-lg-0">
                                                            <a
                                                                href="#"
                                                                className="banner-ads d-flex justify-content-between"
                                                            >
                                                                <div className="b-content">
                                                                    <h5>Online Learning Platform</h5>
                                                                    <span className="badge-lg bg-primary text-small mt-2">
                                                                        All Courses
                                                                    </span>
                                                                </div>
                                                                <img
                                                                    src="assets/images/banner-ads.png"
                                                                    alt=""
                                                                    className="img-fluid banner-img"
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/contact" className="nav-link">
                                                Contact
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/about" className="nav-link">
                                                About Us
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>

        </>
    );
}

export default Header;
