import React from "react";

function Footer() {

  return (
    <>
       {/* <footer className="footer bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget about-footer">
                <div className="f-logo">
                  <a href="index-2.html">
                    <img src="assets/images/f-logo.png" alt="Logo" />
                  </a>
                </div>
                <a
                  href="tel:298228289"
                  className="display-4 cta-link text-secondary"
                >
                  +88 029 345 702
                </a>
                <ul className="mt-4">
                  <li className="d-flex align-items-center">
                    <span>
                      <i className="feather-icon icon-mail" />
                    </span>
                    <a href="https://html.theme-village.com/cdn-cgi/l/email-protection#0c65626a634c7563797e6863616d6562226f6361">
                      <span
                        className="__cf_email__"
                        data-cfemail="355c5b535a754c5a4047515a58545c5b1b565a58"
                      >                       
                        amosay08@hotmail.com
                      </span>
                    </a>
                  </li>
                  <li className="d-flex align-items-center">
                    <span>
                      <i className="feather-icon icon-map-pin" />
                    </span>
                    <span style={{textAlign:"justify"}}>
                    A306, park rest society, behind l&t knowledge city wagoriya, Vadodara(390019)
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 offset-lg-1 col-sm-6">
              <div className="footer-widget">
                <h3 className="widget-title text-white">Company</h3>
                <ul>
                  <li>
                    <a href="about.html">About Us</a>
                  </li>
                  <li>
                    <a href="#">Instructors</a>
                  </li>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                  <li>
                    <a href="#">Services</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="footer-widget">
                <h3 className="widget-title text-white">Categories</h3>
                <ul>
                  <li>
                    <a href="#">Ui/Ux Design</a>
                  </li>
                  <li>
                    <a href="#">Web Development</a>
                  </li>
                  <li>
                    <a href="#">Data Sciences</a>
                  </li>
                  <li>
                    <a href="#">Art &amp; Design</a>
                  </li>
                  <li>
                    <a href="contact.html">Digital Marketing</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 ps-xxl-5 col-sm-6">
              <div className="footer-widget post-widget">
                <h3 className="widget-title text-white">Latest From Blogs</h3>
                <div className="recent-posts">
                  <div className="d-flex recent-entry">
                    <div className="entry-thumb">
                      <a href="single-post.html">
                        <img src="assets/images/post1.jpg" alt="Post" />
                      </a>
                    </div>
                    <div className="entry-content ms-3">
                      <h4 className="display-6 text-info">
                        <a href="single-post.html">
                          Education Turns Filthy Mind Into Open Mind
                        </a>
                      </h4>
                      <div className="entry-meta">
                        <span>
                          <i className="feather-icon icon-calendar" />
                          29 Jun 2024
                        </span>
                        <span>
                          <i className="feather-icon icon-clock" />4 Min Read
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex recent-entry">
                    <div className="entry-thumb">
                      <a href="single-post.html">
                        <img src="assets/images/post2.jpg" alt="Post" />
                      </a>
                    </div>
                    <div className="entry-content ms-3">
                      <h4 className="display-6 text-info">
                        <a href="single-post.html">
                          Education Turns Filthy Mind Into Open Mind
                        </a>
                      </h4>
                      <div className="entry-meta">
                        <span>
                          <i className="feather-icon icon-calendar" />
                          29 Jun 2024
                        </span>
                        <span>
                          <i className="feather-icon icon-clock" />4 Min Read
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row footer-bottom">
            <div className="col-lg-6 col-sm-6 order-2 order-sm-1">
              <p className="m-0 text-mute">
                © 2024 Design by{" "}
                <a className="text-secondary" href="https://www.theme-village.com/">
                  Themevillage
                </a>
                . All Rights Reserved.
              </p>
            </div>
            <div className="col-lg-6 col-sm-6 order-1 order-sm-2">
              <div className="social-share-alt text-lg-end text-mute">
                <a href="#">
                  <img src="assets/images/icons/fb-w.png" alt="" />
                </a>
                <a href="#">
                  <img src="assets/images/icons/tw-w.png" alt="" />
                </a>
                <a href="#">
                  <img src="assets/images/icons/ins-w.png" alt="" />
                </a>
                <a href="#">
                  <img src="assets/images/icons/linkedin-w.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
      <footer className="footer-2 bg-info sec-padding pb-0">
      <div className="container pb-lg-5">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="footer-widget about-footer">
              <div className="f-logo mb-5 text-left">
                <a href="/">
                <h2> <img src="assets/images/logo.png" alt="Logo" /> </h2>
                </a>
              </div>
              <p style={{textAlign:"left"}}>
              Let's Build Your Dream Team Together!
                {/* Education is the most potent weapon change the world give man
                education and he will build a new world */}
              </p>
              <div className="footer-social mt-4 text-left">
                <h3 className="display-5">Follow Us</h3>
                <div className="mt-3">
                  <a href="#">
                    <i className="feather-icon icon-twitter" />
                  </a>
                  <a href="#">
                    <i className="feather-icon icon-facebook" />
                  </a>
                  <a href="#">
                    <i className="feather-icon icon-instagram" />
                  </a>
                  <a href="#">
                    <i className="feather-icon icon-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="footer-widget text-left">
              <h3 className="widget-title">Quick Links</h3>
              <ul>
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li>
                  <a href="/contact">Contact us</a>
                </li>
                {/* <li> 
                  <a href="#">Instructors</a>
                </li> */}
                <li>
                  <a href="#">Careers</a>
                </li>
                <li>
                  <a href="#">Services</a>
                </li>
              
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="footer-widget text-left">
              <h3 className="widget-title">Categories</h3>
              <ul>
              <li>
                  <a href="/contact">Digital Marketing</a>
                </li>
                {/* <li>
                  <a href="#">Ui/Ux Design</a>
                </li> */}
                {/* <li>
                  <a href="#">Web Development</a>
                </li> */}
                <li>
                  <a href="#">Data Sciences</a>
                </li>
                <li>
                  <a href="#">Art &amp; Design</a>
                </li>
                
              </ul>
            </div>
          </div>
          <div className="col-lg-4 ps-xxl-5 col-sm-6">
            <div className="footer-widget post-widget text-left">
              <h3 className="widget-title">Get Latest Update</h3>
              <div className="subscribe-cta">
                <p>Sign up our newsletter &amp; get every single updates weekly</p>
                <form action="#" className="subs-form mt-4">
                  <div className="position-relative form-group">
                    <img src="assets/images/icons/message-dark.png" alt="Message" />
                    <input
                      className="form-control rounded-3 bg-shade"
                      type="email"
                      placeholder="Enter address"
                    />
                  </div>
                  <button className="btn btn-primary w-100 rounded-3 mt-3">
                    Subscribe Now <i className="feather-icon icon-arrow-right" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom bg-blue py-3 mt-5">
        <div className="copy-right text-center">
          <p className="m-0">
            © 2024 Design by{" "}
            CK
            {/* <a className="text-secondary" href="https://www.theme-village.com/">
              Themevillage
            </a> */}
            . All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>

      <div className="back-top">
        <i className="feather-icon icon-chevron-up" />
      </div>
    </>

  );
}

export default Footer;
