import React from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import { useEffect } from 'react';
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
    // Initialize PureCounter after the component is mounted
    if (window.PureCounter) {
      new window.PureCounter(); // Initialize the pure counter
    }

  }, []); // This runs only after the component is mounted

  return (
    <>
      <Header />
      <section
        className="promo-sec"
        style={{
          background: 'url("assets/images/promo-bg.jpg")no-repeat center center / cover'
        }}
      >
        <img src="assets/images/promo-left.png" alt="" className="anim-img" />
        <img src="assets/images/promo-right.png" alt="" className="anim-img anim-right" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="display-2 text-white">About Us</h1>
              <nav aria-label="breadcrumb mt-0">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    About us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec sec-padding position-relative overflow-hidden">
        <div className="anim-img anim-right">
          <img src="assets/images/icons/shape-plate.png" alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-8">
              <div className="about-media overly">
                <div className="category-entry active d-flex p-3 p-xl-4 align-items-center">
                  <span className="icon-lg rounded-circle">
                    <img src="assets/images/icons/graduate.png" alt="" />
                  </span>
                  <div className="cat-info ms-4">
                    <h3 className="display-3">
                      <span
                        data-purecounter-start={0}
                        data-purecounter-end={5000}
                        className="purecounter"
                      >
                        0
                      </span>
                      +
                    </h3>
                    <small>Total</small>
                  </div>
                </div>
                <div className="d-flex align-items-baseline justify-content-between">
                  <img
                    className="img-fluid me-3"
                    src="assets/images/about-lg.jpg"
                    alt="About Image"
                  />
                  <img
                    className="img-fluid d-none d-sm-block"
                    src="assets/images/about-sm.jpg"
                    alt="About Image"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 col-md-8">
              <div className="about-txt">
                <span className="badge-lg bg-primary rounded-5">About Us</span>
                <h2 className="sec-title" style={{ fontSize: '1.8rem' }}>
                  Let’s Build Your Dream Team for a<span className="color">Bright Future</span>Together!
                </h2>
                <div className="about-lists mt-5">
                  {/* <div className="d-flex about-item">
                    <span className="icon icon-sm bg-light rounded-circle ">
                      <img src="assets/images/icons/pencil.png" alt="pencil" />
                    </span>
                    <div className="ms-3">
                      <h3 className="display-5">Education is Power</h3>
                      <p>
                        The cost of ignorance exceed that of education teaches us
                        how to achieve success
                      </p>
                    </div>
                  </div>
                  <div className="d-flex about-item">
                    <span className="icon icon-sm bg-light rounded-circle ">
                      <img src="assets/images/icons/bulb.png" alt="pencil" />
                    </span>
                    <div className="ms-3">
                      <h3 className="display-5">Knowledge for Life</h3>
                      <p>
                        Education is smart enough to change the human mind
                        positively your Door to The Future
                      </p>
                    </div>
                  </div> */}
                  <p className="justify">
                    At Max Education, we believe that people are the backbone of every successful business, while meaningful, sustainable employment has the power to change the world. As a leading provider of manpower solutions, we are dedicated to connecting businesses with the skilled and qualified talent or unskilled nontechnical worker they need to thrive in today’s dynamic marketplace. We believe<br />
                    With years of industry experience, we understand the unique challenges that businesses face when it comes to finding the right workforce. Whether you need temporary staffing, or contractual placements, or specialized professionals, we offer a wide range of workforce solutions designed to meet your requirements. Our commitment to quality, integrity, and customer satisfaction sets us apart as a trusted partner in the manpower industry.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-sec bg-shade sec-padding position-relative">
        <img src="assets/images/icons/telescope-lg.png" alt="" className="anim-img" />
        <img
          src="assets/images/icons/certificate.png"
          alt=""
          className="anim-img anim-right bottom-0"
        />
        <div className="container">
          <div className="sec-intro text-center">
            {/* <span className="badge-lg bg-primary rounded-5">Why Choose Us?</span> */}
            <h2 className="sec-title">
            Why <span className="color">Choose</span> Us?
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="accordion-1" id="accordion-faq">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is the Importance of Education?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordion-faq"
                  >
                    <div className="accordion-body">
                      <p>
                        Teachers play a central role in education. They impart
                        knowledge, facilitate learning, and help students develop
                        critical thinking skills. Teachers also contribute to the
                        social and emotional development of students.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header mt-3" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What are the Different Levels of Education?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordion-faq"
                  >
                    <div className="accordion-body">
                      <p>
                        Teachers play a central role in education. They impart
                        knowledge, facilitate learning, and help students develop
                        critical thinking skills. Teachers also contribute to the
                        social and emotional development of students.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What is the Role of Teachers in Education?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordion-faq"
                  >
                    <div className="accordion-body">
                      <p>
                        Teachers play a central role in education. They impart
                        knowledge, facilitate learning, and help students develop
                        critical thinking skills. Teachers also contribute to the
                        social and emotional development of students.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      What is the Difference Between Public and Private Education?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordion-faq"
                  >
                    <div className="accordion-body">
                      <p>
                        Teachers play a central role in education. They impart
                        knowledge, facilitate learning, and help students develop
                        critical thinking skills. Teachers also contribute to the
                        social and emotional development of students.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="newsletter bg-primary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <span className="badge-lg bg-white text-primary rounded-5">
                Get Every Single Updates
              </span>
              <h2 className="display-2 fw-bold text-info">
                Subscribe <span className="color-info">Newsletter</span>
              </h2>
            </div>
            <div className="col-lg-4">
              <div className="newsletter-form">
                <form action="#">
                  <input
                    className="form-control rounded-5 bg-info"
                    type="email"
                    placeholder="Email Address"
                  />
                  <button className="btn btn-secondary w-100 rounded-5 mt-4">
                    Subscribe Newsletter
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <Footer />
    </>

  );
}

export default About;
